<template>
  <div :class="'el-input ' + (showCountryCodeSelector ? 'with-country-code' : '' )">
    <the-mask
      :mask="mask"
      class="el-input__inner mobile_number"
      type="tel"
      autocomplete="off"
      name="mobile_number"
      :masked="true"
      :value="phone"
      @input.native="updateInput($event)"
      ref="mobileNumber"
      :placeholder="placeholder" />
    <el-select class="country-code-selector" size="mini" v-if="showCountryCodeSelector" v-model="selectedCountryCode">
      <el-option v-for="c in supportedCountries" :key="c.dial_code" :value="c.dial_code" :label="c.dial_code">
        <span class="flag">
          <img :src="flag(c)" :alt="c.name">
        </span>
        <span class="select-country-code">
          {{ c.dial_code }}
        </span>
      </el-option>
    </el-select>
  </div>
</template>
<script>
import { getSupportedCountries, getCountryDetailsFromPhoneNumber } from '@/utils/env';
import { TheMask } from 'vue-the-mask';
export default {
  components: { TheMask },
  props: {
    value: {
      type: String,
      default: ''
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    showCountryCodeSelector: {
      type: Boolean,
      default: false
    }
  },
  computed :{
    supportedCountries() {
      return getSupportedCountries();
    },
    selectedCountry() {
      const selectedCountry = this.supportedCountries.find((c) => c.dial_code === this.selectedCountryCode);
      return selectedCountry;
    },
    mask() {
      const selectedCountry = this.supportedCountries.find((c) => c.dial_code === this.selectedCountryCode);
      if(this.showCountryCodeSelector) {
        return selectedCountry.mask;
      }
      else
        return selectedCountry.dial_code + ' ' + selectedCountry.mask;
    },
    placeholder() {
      const mask = this.mask;
      let i = 0;
      const numbers = mask.split('').map((c) => {
        if(c === '#') {
          i++;
          return i >= 10 ? i % 10 : i;
        }
        return c;
      }).join('');

      return numbers;
    },
  },
  data() {
    return {
      selectedCountryCode: '+1',
      phone: ''
    }
  },
  mounted() {
    this.autofocus ? this.$refs.mobileNumber.$el.focus() : '';
  },
  methods: {
    updateInput(event) {
      let value = event.target.value;
      if (value) {
        if(this.showCountryCodeSelector) {
          this.$emit('input', {
            value: this.selectedCountryCode + (value.replace(/\D+/g, '')),
            number: value,
            dial_code: this.selectedCountryCode,
            selectedCountry: this.selectedCountry,
            isValid: this.selectedCountry.mask.length === value.length
          });
        } else {
          this.$emit('input', '+' + value.replace(/\D+/g, ''));
        }
      }
      else this.$emit('input', null);
    },
    flag(c) {
      const assetsUrl = process.env.VUE_APP_ASSETS;
      return `${assetsUrl}/flags/${c.code}.png`;
    }
  },
  watch: {
    value: {
      handler: function (newVal) {
        if(this.showCountryCodeSelector && newVal !== '' && newVal !== null) {
          const {dial_code} = getCountryDetailsFromPhoneNumber(newVal);
          this.selectedCountryCode = dial_code;
          this.phone = newVal.replace(dial_code, '');
        } else {
          this.phone = newVal;
        }
      },
      immediate: true
    }
  }
};
</script>
<style lang="scss">
.with-country-code {
  position: relative;
  .mobile_number {
    padding-left: 75px;
  }
  .country-code-selector {
    position: absolute;
    left: 7px;
    top: 4px;
    .el-input__inner {
      max-width: 56px;
      padding: 5px 12px 5px 4px;
      display: inline;
      height: 34px;
      line-height: 28px;
      background: #F6F7F9;
      border-radius: 0;
      border: 1px solid #F6F7F9 !important;;
      &:hover, &:focus {
        border: 1px solid #F6F7F9 !important;
      }
    }
  }
}
.select-country-code {
  font-size: 12px;
}
</style>
